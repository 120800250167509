<template>
    <div class="user-picture">
        <figure>
            <img class="photo" :src="picture">
        </figure>
        <img v-if="isVerified" class="verified-user" src="@assets/img/icons/verified-user.svg">
    </div>
</template>

<script>
export default {
    name: "UserPicture",
    props: {
        picture: {
            type: String,
            required: true
        },
        isVerified: {
            type: Boolean
        }
    }
}
</script>

<style lang="scss" scoped>
.user-picture {
    position: relative;

    figure {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        overflow: hidden;
        position: relative;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width: $sm) {
            width: 40px;
            height: 40px;
        }

        .photo {
            width: 100%;
        }
    }

    .verified-user {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
</style>
